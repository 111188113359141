<template>
  <div class="widget-preview-container margin-10-auto">
    <!-- el select to show list of competition -->
    <el-row :gutter="10">
      <el-col :span="isMobile? 24: 12">
      <el-select placeholder="Select Competiton" value-key="_id" v-model="compId">
        <el-option
           v-for="competition in competitions"
           :key="competition._id"
           :label="competition.name"
           :value="competition._id"
         >
         </el-option>
      </el-select>
      </el-col>
    </el-row>
    <div v-if="useSortOptions">
      <el-table
        v-if="(!get(ladder, 'competition.hasPools')) || (get(ladder, 'competition.hasPools') && (!teamsByPool || Object.keys(teamsByPool) === 0))"
        stripe
        style="width: 100%"
        class="ladder-table"
        header-row-class-name="table-header"
        :data="teamsArrangeByPosition">
        <el-table-column
          label="Team"
          :width="breakpoint === '375px'? 230:410"
          row-class-name="ladder-row"
          >
          <template slot-scope="scope" >
            <div :class="{'position-logo-name':true,'grid-with-crest':widget.widgetSettings.showTeamCrest, 'grid-without-crest': !widget.widgetSettings.showTeamCrest}">
              <div class="position">{{scope.$index +1}}</div>
              <div class="logo-name">
                <div v-if="widget.widgetSettings.showTeamCrest" class="team-logo ml-2">
                      <img
                        :src="getTeamLogo(get(scope, 'row._id', ''))"
                        style="object-fit: fill; width: 24px"
                      />
                </div>
                <div class="name">{{scope.row.name}}</div>
                </div>
              </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="sortBy in selectedSortByOptions"
          :key="sortBy"
          :prop="`stats.${sortBy}`"
          :label="getAbbreviation(sortBy)"
          :width="breakpoint === '375px'? 40:80">
          <template slot-scope="scope" >
              <div>{{get(scope, `row.stats.${sortBy}`,0)}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-else
        v-for="pool in Object.keys(teamsByPool)"
        stripe
        :key="pool"
        header-row-class-name="table-header"
        :data="teamsByPool[pool]"
        >
          <el-table-column :label="processPoolName(pool)" align="center">
            <el-table-column
              label="Team"
              :width="breakpoint === '375px'? 230:410"
              row-class-name="ladder-row"
              >
              <template slot-scope="scope" >
                <div :class="{'position-logo-name':true, 'grid-with-crest':widget.widgetSettings.showTeamCrest, 'grid-without-crest': !widget.widgetSettings.showTeamCrest}">
                  <div class="position">{{scope.$index +1}}</div>
                  <div class="logo-name">
                    <div v-if="widget.widgetSettings.showTeamCrest" class="team-logo ml-2">
                          <img
                            :src="getTeamLogo(get(scope, 'row._id', ''))"
                            style="object-fit: fill; width: 24px"
                          />
                    </div>
                    <div class="name">{{scope.row.name}}</div>
                    </div>
                  </div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="sortBy in selectedSortByOptions"
              :key="sortBy"
              :prop="`stats.${sortBy}`"
              :label="getAbbreviation(sortBy)"
              :width="breakpoint === '375px'? 40:80">
              <template slot-scope="scope" >
                <div>{{get(scope, `row.stats.${sortBy}`,0)}}</div>
              </template>
            </el-table-column>
          </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <el-table
        v-if="(!get(ladder, 'competition.hasPools')) || (get(ladder, 'competition.hasPools') && (!teamsByPool || Object.keys(teamsByPool) === 0))"
        stripe

        class="ladder-table"
        header-row-class-name="table-header"
        :data="get(ladder, 'teams', [])">
        <el-table-column
          label="Team"
          :min-width=200
          row-class-name="ladder-row"
          >
          <template slot-scope="scope" >
            <div class="position-logo-name">
              <div class="position">{{scope.$index +1}}</div>
              <div class="logo-name">
                <div v-if="widget.widgetSettings.showTeamCrest" class="team-logo ml-2">
                      <img
                        :src="getTeamLogo(get(scope, 'row._id', ''))"
                        style="object-fit: fill; width: 24px"
                      />
                </div>
                <div class="name">{{scope.row.name}}</div>
                </div>
              </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="stats.matchesPlayed"
          label="Pl"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.matchesPlayed',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="stats.matchesWon"
          label="W"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.matchesWon',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMobile"
          prop="stats.matchesDrawn"
          label="D"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.matchesDrawn',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMobile"
          prop="stats.matchesLost"
          label="L"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.matchesLost',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMobile && isFit && national_id !== 2"
          prop="stats.byes"
          label="B"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.byes',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMobile && isFit && national_id !== 2"
          prop="stats.pointsFor"
          :label="`${getScoringLabel()}F`"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.pointsFor',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMobile && isFit && national_id !== 2"
          prop="stats.pointsAgainst"
          :label="`${getScoringLabel()}A`"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.pointsAgainst',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMobile && national_id !== 2"
          prop="stats.pointsDifference"
          :label="`${getScoringLabel()}D`"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.pointsDifference',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isMobile && national_id === 2"
          prop="stats.pointsDifferencePercentage"
          label="%"
          :min-width="60">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.pointsDifferencePercentage',0 )}}</div>
          </template>
        </el-table-column>
        <el-table-column
        v-if="!isMobile && widget.widgetSettings.showBonusPoints && national_id !== 2"
          prop="stats.pointsBonus"
          label="BP"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.pointsBonus',0)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="stats.totalMatchPoints"
          label="PTS"
          :min-width="40">
          <template slot-scope="scope" >
              <div>{{get(scope, 'row.stats.totalMatchPoints',0)}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-else
        v-for="pool in Object.keys(teamsByPool)"
        stripe
        :key="pool"
        header-row-class-name="table-header"
        :data="teamsByPool[pool]"
        >
        <el-table-column :label="processPoolName(pool)" align="center">
          <el-table-column
            label="Team"
            :min-width="isMobile? 190:370"
            row-class-name="ladder-row"
            >
            <template slot-scope="scope" >
              <div class="position-logo-name">
                <div class="position">{{scope.$index +1}}</div>
                <div class="logo-name">
                  <div v-if="widget.widgetSettings.showTeamCrest" class="team-logo ml-2">
                        <img
                          :src="getTeamLogo(get(scope, 'row._id', ''))"
                          style="object-fit: fill; width: 24px"
                        />
                  </div>
                  <div class="name">{{scope.row.name}}</div>
                  </div>
                </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="stats.matchesPlayed"
            label="Pl"
            :width="isMobile? 40:80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.matchesPlayed',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="stats.matchesWon"
            label="W"
            :width="isMobile? 40:80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.matchesWon',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isMobile"
            prop="stats.matchesDrawn"
            label="D"
            :width="isMobile? 40:80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.matchesDrawn',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isMobile"
            prop="stats.matchesLost"
            label="L"
            :width="isMobile? 40:80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.matchesLost',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isMobile && isFit && national_id !== 2"
            prop="stats.byes"
            label="B"
            width="80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.byes',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isMobile && isFit && national_id !== 2"
            prop="stats.pointsFor"
            :label="`${getScoringLabel()}F`"
            width="80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.pointsFor',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isMobile && isFit && national_id !== 2"
            prop="stats.pointsAgainst"
            :label="`${getScoringLabel()}A`"
            width="80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.pointsAgainst',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isMobile && national_id !== 2"
            prop="stats.pointsDifference"
            :label="`${getScoringLabel()}D`"
            width="80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.pointsDifference',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column v-if="!isMobile && national_id === 2"
            prop="stats.pointsDifferencePercentage"
            label="%"
            width="80">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.pointsDifferencePercentage',0 )}}%</div>
            </template>
          </el-table-column>
          <el-table-column
          v-if="!sMobile && widget.widgetSettings.showBonusPoints && national_id !== 2"
            prop="stats.pointsBonus"
            label="BP"
            :width="breakpoint === '375px'? 45:70">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.pointsBonus',0)}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="stats.totalMatchPoints"
            label="PTS"
            :width="isMobile? 45:70">
            <template slot-scope="scope" >
                <div>{{get(scope, 'row.stats.totalMatchPoints',0)}}</div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

const sortBy = [
  { name: 'Total Match Points', value: 'totalMatchPoints', abbreviation: 'Pts' },
  { name: 'Points Difference', value: 'pointsDifference', abbreviation: '+/-' },
  { name: 'Points For', value: 'pointsFor', abbreviation: '+' },
  { name: 'Points Against', value: 'pointsAgainst', abbreviation: '-' },
  { name: 'Points Bonus', value: 'pointsBonus', abbreviation: 'BP' },
  { name: 'Matches Played', value: 'matchesPlayed', abbreviation: 'P' },
  { name: 'Matches Won', value: 'matchesWon', abbreviation: 'W' },
  { name: 'Matches Lost', value: 'matchesLost', abbreviation: 'L' },
  { name: 'Matches Drawn', value: 'matchesDrawn', abbreviation: 'D' },
  { name: 'BYES', value: 'byes', abbreviation: 'B' },
  { name: 'Score Ratio', value: 'scoreRatio', abbreviation: 'SR' },
  { name: 'Points Ratio', value: 'pointsRatio', abbreviation: 'PR' },
  { name: 'Number Of Forfeits', value: 'numberOfForfeits', abbreviation: 'FF' },
  { name: 'Number Forfeits Win', value: 'numberForfeitsWin', abbreviation: 'FW' },
  { name: 'Number Forfeits Loss', value: 'numberForfeitsLoss', abbreviation: 'FL' },
  { name: 'Points Difference Percentage', value: 'pointsDifferencePercentage', abbreviation: '%' },
];

export default {
  name: 'LadderView',
  props: ['http', 'widget', 'national_id'],
  data() {
    return {
      get: _.get,
      // breakpoint: '1190px',
      allTeams: [],
      isFit: false,
      compId: undefined,
      ladder: undefined,
      pointsADJVisible: false,
      pointsADJType: false,
      pointsADJTeam: false,
      pointsADJValue: false,
      tableProps: {
        border: false,
      },
      isMobile: false,

    };
  },
  mounted() {
    if (_.get(this.widget, 'widgetSettings.competitionList.length', 0) > 0) {
      this.compId = this.widget.widgetSettings.competitionList[0]._id;
    }

    document.documentElement.style.setProperty('--primary-color', process.env[`VUE_APP_${this.national_id}_PRIMARY_COLOR`]);
    document.documentElement.style.setProperty('--secondary-color', process.env[`VUE_APP_${this.national_id}_SECONDARY_COLOR`]);
    document.documentElement.style.setProperty('--highlight-color', process.env[`VUE_APP_${this.national_id}_HIGHLIGHT_COLOR`]);

    this.handleResize();
    this.addStyle();

    // resize event listener
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    addStyle(breakpoint) {
      if (!breakpoint) {
        // eslint-disable-next-line no-param-reassign
        breakpoint = this.breakpoint;
      }
      setTimeout(() => {
        const elTables = document.getElementsByClassName('el-table');
        const elTablesArray = Array.from(elTables);
        const elTableRows = document.getElementsByClassName('el-table__row');
        const elTableRowsArray = Array.from(elTableRows);
        if (this.isMobile) {
          elTablesArray.forEach((el) => {
            el.setAttribute('style', 'border-end-end-radius: 0px !important; border-start-end-radius: 0px !important; border-end-start-radius: 10px !important; border-start-start-radius: 10px !important;');
          });
          elTableRowsArray.forEach((row) => {
            row.setAttribute('style', 'height: 48px !important;');
          });
        } else {
          elTablesArray.forEach((el) => {
            el.setAttribute('style', 'border-radius: 10px !important; max-width: 1190px !important;');
          });
          elTableRowsArray.forEach((row) => {
            row.setAttribute('style', 'height: 60px !important;');
          });
        }
      }, 200);
    },
    getScoringLabel() {
      const label = _.get(this.ladder, 'teams[0].stats');
      // check if ladder has pointsFor or scoresFor or goalsFor
      if (label && label.pointsFor) {
        return 'P';
      } if (label && label.scoresFor) {
        return 'S';
      } if (label && label.goalsFor) {
        return 'G';
      }
      return 'P';
    },
    processPoolName(name) {
      return name !== 'undefined' ? name : 'Unknown Pool';
    },
    getTeamLogo(team) {
      if (team) {
        const foundTeam = _.find(this.allTeams, { _id: team });
        if (foundTeam) {
          return _.get(foundTeam, 'meta.imgUrl', this.clubPlaceholder);
        }
        return this.clubPlaceholder;
      }
      return this.clubPlaceholder;
    },
    handleResize() {
      // const width = window.innerWidth;
      const widgetContainer = document.getElementsByClassName('widget-preview-container');
      const widgetContainerWidth = widgetContainer[0].offsetWidth;
      // get window client width

      if (widgetContainerWidth <= 450) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

      if (this.widget.widgetSettings.showBonusPoints) {
        if (widgetContainerWidth < 1231) {
          this.isFit = false;
        } else {
          this.isFit = true;
        }
      } else if (widgetContainerWidth < 1161) {
        this.isFit = false;
      } else {
        this.isFit = true;
      }
    },
    getAbbreviation(sortByValue) {
      const foundSortBy = _.find(sortBy, { value: sortByValue });
      const abbreviation = _.get(foundSortBy, 'abbreviation');
      if (abbreviation) {
        return abbreviation;
      }
      return this.abbreviate(this.getSortByName(sortByValue));
    },
    abbreviate(str) {
      return _.map(_.words(str), (word) => word[0]).join("").toUpperCase();
    },
    getSortByName(value) {
      return _.get(_.find(sortBy, { value }), 'name', '');
    },
  },
  computed: {
    isCompTypeUseSortOptions() {
      return _.get(this.ladder, 'compTypeLaddertemplate.useSortOptions', false);
    },
    useSortOptions() {
      if (this.isCompTypeUseSortOptions) {
        return true;
      }
      return _.get(this.ladder, 'laddertemplate.useSortOptions', false);
    },
    selectedSortByOptions() {
      if (this.isCompTypeUseSortOptions) {
        return _.get(this.ladder, 'compTypeLaddertemplate.selectedSortByOptions', []);
      }
      return _.get(this.ladder, 'laddertemplate.selectedSortByOptions', []);
    },
    clubPlaceholder() {
      return process.env[`VUE_APP_${this.national_id}_LOGO_ACCOUNT`];
    },
    competitions() {
      return _.get(this, 'widget.widgetSettings.competitionList', []);
    },
    teamsByPool() {
      // const { teams } = this.ladder;
      const teams = this.teamsArrangeByPosition;
      return teams
        ? teams.reduce((result, item) => {
          const value = item.pool;
          // eslint-disable-next-line no-param-reassign
          result[value] = (result[value] || []).concat(item);
          return result;
        }, {}) : false;
    },
    teamsArrangeByPosition() {
      const roundSelected = this.filterRound;
      if (roundSelected === 0) {
        return _.sortBy(this.ladder.teams, ['position']);
      }
      const roundTeams = _.get(this.ladder, `teamsWithStatsByRound.perRound.${roundSelected}`, []);
      return _.sortBy(roundTeams, ['position']);
    },
  },
  watch: {
    compId: {
      handler(val) {
        // fetch ladder data
        if (val) {
          try {
            this.$store.commit('loading', true);
            this.http.get(`assemble/api/v1/widget/ladders/competition/${val}`)
              .then((response) => {
                this.ladder = response.data.data;
                this.allTeams = response.data.teams;
                this.addStyle();
                this.$store.commit('loading', false);
              })
              .catch((error) => {
                this.$store.commit('loading', false);
                this.$message.error(error);
              });
          } catch (error) {
            this.$store.commit('loading', true);
          }
        }
      },
      immediate: true,
    },
    breakpoint: {
      handler(val) {
        this.addStyle(val);
      },
      immediate: true,
    },
  }
};
</script>

<style lang="scss" scoped>

::v-deep.el-table {
  margin: 0 auto;
    margin-top: 2rem;
    border: none;
    box-shadow: none;
    td {
      border: none;
      color: #000;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.82;
      letter-spacing: -0.1px;
      &.el-table__cell{
        padding: 0;
      }
      hr{
        padding:0px;
      }
    }
    tr {
      height: 48px;
    }

    th {
         border: none !important;
         background: var(--highlight-color);
         height: 58px !important;
         color: rgba($color: #fff, $alpha: 0.6)
        //  at cell set apacity to 0.5

    }

    //get first row and set background color to green
    // tr first-child

    & thead.is-group th.el-table__cell{
      background: var(--secondary-color);
      color: white;
      height: 51px;
    }
  }

// .el-table{
//   border-start-end-radius: 0px !important;
//   border-end-end-radius: 0px !important;
// }
::v-deep.el-select .el-input__inner, .el-input .el-input__inner, .el-input-number .el-input__inner{
  border-radius: 10px;
  // border: solid 1px #fff;
}
.position-logo-name{
  display: grid;
  grid-template-columns: 1fr 9fr;
  align-items: center;
  .position{
    text-align: center;
  }
  .logo-name{
    // display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 9fr;
    .team-logo{
      width: 24px;
      height: 24px;
      // flex-basis: 10%;
    }
    .name{
      // only one line
      margin-left: 10px;
      // flex-basis: 85%;

      font-size: 1.1rem;
      font-weight: 400 !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.1px;
      color: #000;
    }
  }
}
</style>
